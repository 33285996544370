import React from "react"
import IMAGE1 from "../../images/explore/SSLLC-Services_-Sell-Equipment.jpg"
import IMAGE2 from "../../images/explore/shutterstock_2436587193-600x888.jpg"
import IMAGE3 from "../../images/explore/SSLLC-Blog-Image-Fameco-Announcement-3-600x800.jpg"

export default function WhatsNewComponent() {
  return (
    <>
      <div className="bg-light-gray py-16 shadow-custom-in">
        <div className="container w-full max-w-screen-xl flex flex-col">
          <h2 className="text-royal-blue">Explore What's New</h2>
          {/* Blog */}
          <div className="flex flex-col md:flex-row w-full bg-white rounded-md overflow-hidden mt-8">
            <div className="w-full md:w-2/5">
              <img
                src={IMAGE1}
                alt="Modern laboratory interior out of focus, including equipment."
                className="object-cover w-full h-full"
              />
            </div>
            <div className="w-full md:w-3/5 p-8 justify-between flex flex-col items-start">
              <div className="w-full  ">
                <h6 className="text-medium-gray pb-2">FEATURED BLOG</h6>
                <h4>
                  Maximizing Your Research Budget: Why More Labs are Turning to
                  Lab Equipment Resellers
                </h4>
              </div>   
              <a
              href="https://connect.ssllc.com/learning-center/maximizing-your-research-budget-why-more-labs-are-turning-to-lab-equipment-resellers/"
              className="bg-light-gray text-[1.2rem] text-charcoal hover:bg-royal-blue hover:text-white py-3 px-6 rounded-md mt-4 ">
                Read More &gt;
              </a>
            </div>
          </div>

          {/* Events and News */}
          <div className="flex flex-col md:flex-row w-full mt-10 space-x-0 md:space-x-12 space-y-10 md:space-y-0 justify-between md:justify-stretch">
            {/* Event */}
            <div className="w-full md:w-1/2">
              <div className="flex flex-col lg:flex-row w-full bg-white rounded-md overflow-hidden h-full">
                <div className="w-full lg:w-2/5">
                  <img
                    src={IMAGE2}
                    alt="Modern laboratory interior out of focus, including equipment."
                    className="object-cover w-full h-56 lg:h-full"
                  />
                </div>
                <div className="w-full lg:w-3/5 p-8 justify-between flex flex-col flex-grow items-start">
                  <div className="w-full">
                    <h6 className="text-medium-gray pb-2">LATEST EVENTS</h6>
                    <h4 className="text-balance">
                      Bio International Convention 2025
                    </h4>
                    <h6 className="text-royal-blue">
                      Jun 16, 2025 – Jun 19, 2025
                    </h6>
                    <span className="font-light">Boston, MA</span>
                    <br />
                  </div>
                  <a
                  href="https://connect.ssllc.com/learning-center/events/bio-international-convention-2025/"
                  className="bg-light-gray text-[1.2rem] text-charcoal hover:bg-royal-blue hover:text-white py-3 px-4 rounded-md mt-4 w-full">
                    Read More &gt;
                  </a>
                </div>
              </div>
            </div>
            {/* News */}
            <div className="w-full md:w-1/2">
              <div className="flex flex-col lg:flex-row w-full bg-white rounded-md overflow-hidden h-full">
                <div className="w-full lg:w-2/5">
                  <img
                    src={IMAGE3}
                    alt="Modern laboratory interior out of focus, including equipment."
                    className="object-cover w-full h-56 lg:h-full"
                  />
                </div>
                <div className="w-full lg:w-3/5 p-8 justify-between flex flex-col flex-grow items-start">
                  <div className="w-full md:w-2/3">
                    <h6 className="text-medium-gray pb-2">LATEST NEWS</h6>
                    <h4 className="text-balance">
                      Surplus Solutions Acquires FAMECO
                    </h4>
                  </div>
                  <a
                  href="https://connect.ssllc.com/learning-center/news/surplus-solutions-acquires-fameco/"
                  className="bg-light-gray text-[1.2rem] text-charcoal hover:bg-royal-blue hover:text-white py-3 px-4 rounded-md mt-4 w-full">
                    Read More &gt;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
